import type { ReactNode } from 'react';
import type React from 'react';
import classNames from 'classnames';
import { Card } from '@pledge-earth/web-components';
import type { ButtonProps } from '@pledge-earth/product-language';
import { ArrowLeftIcon, Button, FeaturedIcon } from '@pledge-earth/product-language';

import './ErrorBase.scss';

export interface Props {
  icon?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  className?: string;
  secondaryActionProps?: ButtonProps;
  primaryActionProps?: ButtonProps;
  style?: React.CSSProperties;
}

export function ErrorBase(props: Props): JSX.Element {
  return (
    <Card className="ErrorBase__card">
      <div className={classNames('ErrorBase', props.className)}>
        {props.icon ? (
          <div className="ErrorBase__icon-row" /** center */>
            <FeaturedIcon size="40">{props.icon}</FeaturedIcon>
          </div>
        ) : null}
        <div className="ErrorBase__title-row">{props.title}</div>
        <div className="ErrorBase__description-row">{props.description}</div>
        <div className="ErrorBase__actions-row flex min-w-0 flex-wrap justify-center">
          {props.secondaryActionProps?.onPress && props.secondaryActionProps?.children ? (
            <div className="ErrorBase__actions-row__column ErrorBase__actions-row__column--secondary relative min-h-px max-w-full">
              <div>
                <Button
                  {...props.secondaryActionProps}
                  className={classNames(
                    'ErrorBase__actions-row__button',
                    'ErrorBase__actions-row__button--secondary',
                    props.secondaryActionProps?.className,
                  )}
                >
                  <ArrowLeftIcon /> {props.secondaryActionProps?.children}
                </Button>
              </div>
            </div>
          ) : null}
          {props.primaryActionProps?.onPress && props.primaryActionProps?.children ? (
            <div className="ErrorBase__actions-row__column ErrorBase__actions-row__column--primary relative min-h-px max-w-full">
              <div>
                <Button
                  variant="primary"
                  {...props.primaryActionProps}
                  className={classNames(
                    'ErrorBase__actions-row__button',
                    'ErrorBase__actions-row__button--primary',
                    props.primaryActionProps?.className,
                  )}
                >
                  {props.primaryActionProps?.children}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
}
