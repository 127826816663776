import { all, takeEvery, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import _store from 'store';
import { invoke } from 'lodash';

import * as usr from '../../services/user/userService';
import { router } from '../../routers/router_client';
import { ErrorCode as ConfirmSignUpErrorCode } from '../../components/Auth/ConfirmSignUpError/ConfirmSignUpError';
import { getIntl } from '../../locales/intl';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

import {
  passwordChanged,
  inviteConfirmed,
  mfaConfirmed,
  signUpConfirmed,
  forgotPassword as forgotPasswordAction,
  initialState,
  loadCurrentAccount as loadCurrentAccountAction,
  login as loginAction,
  logout as logoutAction,
  resentConfirmSignUp,
  passwordReset,
  setUserState,
  signUp as signUpAction,
} from './reducers';

function* login({ payload }: ReturnType<typeof loginAction>) {
  const { email, password, recaptcha } = payload;

  yield put(
    setUserState({
      loading: true,
      badPassword: false,
    }),
  );

  try {
    const success = yield call(usr.login, email, password, recaptcha);
    if (success) {
      if (success.logged) {
        const redirectTo = _store.get('redirectTo');

        yield put(
          loadCurrentAccountAction({
            redirectTo: redirectTo || '/',
          }),
        );
      }
      if (success.smsMfaRequired) {
        const { session, phoneNumber } = success;
        yield router.navigate('/auth/confirm-mfa', {
          state: { session, email, phoneNumber, password },
        });
        yield put(
          setUserState({
            loading: false,
          }),
        );
      }
      if (success.verifyEmailRequired) {
        const { session } = success;
        yield router.navigate('/auth/sign-up', {
          state: {
            emailSent: true,
            email,
          },
          replace: true,
        });
        yield put(
          setUserState({
            loading: false,
          }),
        );

        // Save session in local storage
        _store.set('custom_auth_session', session);
      }

      if (success.resetPwdRequired) {
        yield router.navigate('/auth/forgot-password', {
          state: { resetPwdRequired: true, email },
        });

        yield put(
          setUserState({
            loading: false,
          }),
        );
      }
    } else {
      yield put(
        setUserState({
          badPassword: true,
          loading: false,
        }),
      );
    }
  } catch (err) {
    yield put(
      setUserState({
        loading: false,
        badPassword: true,
      }),
    );
  }
}

function* loadCurrentAccount(data?: ReturnType<typeof loadCurrentAccountAction>) {
  yield put(
    setUserState({
      loading: true,
    }),
  );

  try {
    const response = yield call(usr.currentAccount);
    if (response) {
      const userData = response;
      const {
        email,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        first_name,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        last_name,
        username,
        role,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        job_function,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        user_id,
        language,
        avatar,
        client,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_id,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_public_id,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_avatar,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_logo,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_function,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_is_created,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_domain,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        client_currency,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        user_intercomHash,
        identities,
        source,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
        account_state,
        subscription,
        calculator,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        emission_import_configuration,
      } = userData;

      // Push *static* customer data to the GTM dataLayer
      // which will forward this to Intercom on
      // next tag trigger.
      //
      // Do *not* map the pushed user id to the
      // intercom data attributes as they will
      // assume it should be part of the intercom
      // hash as well, they would then reject all
      // requests as fraudulent.
      //
      // https://developers.intercom.com/installing-intercom/docs/enable-identity-verification-on-your-web-product
      //
      invoke(window, ['dataLayer', 'push'], {
        event: 'loadCurrentAccount',
        userIntercomHash: user_intercomHash,
        userId: user_id,
        userEmail: email,
        userRole: role,
        clientId: client_id,
        clientWebsite: client_domain,
        clientFunction: client_function,
        clientName: client,
        clientSubscriptionPlanId: subscription.plan_id,
        clientSubscriptionStatus: subscription.status,
      });

      yield put(
        setUserState({
          first_name,
          last_name,
          role,
          job_function,
          email,
          user_id,
          language,
          avatar,
          client,
          client_id,
          client_public_id,
          client_function,
          client_avatar,
          client_logo,
          client_is_created,
          client_domain,
          user_intercomHash,
          client_currency,
          identities,
          source,
          account_state,
          authenticated: true,
          subscription,
          calculator,
          emission_import_configuration,
        }),
      );

      // Set username in local storage for refreshing token
      _store.set('username', username);

      // Currently broken - the backend language is in the format EN_GB rather than en-GB, which breaks the localisation
      // provider. Not a problem right now since languages other than English are currently disabled.
      // yield put(localeChanged({ language }));

      // Identify the user in Sentry
      Sentry.setUser({
        id: user_id,
        username: email,
        email,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(
      setUserState({
        loading: false,
      }),
    );

    if (data?.payload?.redirectTo) {
      yield router.navigate(`${data?.payload?.redirectTo}`);
    }
  }
}

function* logout() {
  yield call(usr.logout);
  yield put(setUserState(initialState));

  // Redirect to sign in page
  yield router.navigate('/auth/sign-in');
}

function* changePassword({ payload }: ReturnType<typeof passwordChanged>) {
  const intl = getIntl();
  const { oldPassword, newPassword } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );
  try {
    const response = yield call(usr.changePassword, oldPassword, newPassword);
    if (response) {
      yield call(showSuccessToast, { description: intl.formatMessage({ id: `alert.success` }) });
    } else {
      yield call(showErrorToast, { description: intl.formatMessage({ id: `alert.error.short` }) });
    }
  } catch (err) {
    yield call(showErrorToast, { description: intl.formatMessage({ id: `alert.error.short` }) });
  } finally {
    yield put(
      setUserState({
        loading: false,
      }),
    );
  }
}

function* forgotPassword({ payload }: ReturnType<typeof forgotPasswordAction>) {
  const { email } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );
  try {
    const response = yield call(usr.forgotPassword, email);
    if (response) {
      yield router.navigate('/auth/forgot-password', {
        state: {
          emailSent: true,
        },
        replace: true,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(
      setUserState({
        loading: false,
      }),
    );
  }
}

function* resetPassword({ payload }: ReturnType<typeof passwordReset>) {
  const { email, password, passwordConfirmation, confirmationCode } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );
  try {
    const response = yield call(usr.resetPassword, email, password, passwordConfirmation, confirmationCode);
    if (response) {
      yield router.navigate('/auth/reset-password', {
        state: {
          passwordUpdated: true,
          email,
          password,
        },
        replace: true,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(
      setUserState({
        loading: false,
      }),
    );
  }
}

function* confirmInvite({ payload }: ReturnType<typeof inviteConfirmed>) {
  const { email, token, password } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );

  try {
    const response = yield call(usr.confirmInvite, email, token, password);
    if (response) {
      yield put(loadCurrentAccountAction({}));
    } else {
      yield put(
        setUserState({
          loading: false,
        }),
      );
      yield router.navigate('/auth/sign-up', {
        state: {
          confirmInviteError: true,
        },
      });
    }
  } catch (err) {
    yield put(
      setUserState({
        loading: false,
      }),
    );
    yield router.navigate('/auth/sign-up', {
      state: {
        confirmInviteError: true,
      },
    });
  }
}

function* signUp({ payload }: ReturnType<typeof signUpAction>) {
  const { email, password, redirectTo, originTrackingData } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );

  try {
    const response = yield call(usr.signUp, email, password, redirectTo, originTrackingData);
    if (response) {
      if (!response.success || !response.session || response.error) {
        yield router.navigate('/auth/sign-up', {
          state: {
            email,
            emailCheckSuccess: response?.success,
            emailCheckError: response?.error,
          },
          replace: true,
        });
      } else {
        invoke(window, ['dataLayer', 'push'], {
          event: 'sign_up',
          userId: response.user_id,
          userEmail: email,
        });

        yield router.navigate('/auth/sign-up', {
          state: {
            emailSent: true,
            email,
          },
          replace: true,
        });

        // Save session in local storage
        _store.set('custom_auth_session', response.session);
      }
    }
    yield put(
      setUserState({
        loading: false,
      }),
    );
  } catch (err) {
    yield put(
      setUserState({
        loading: false,
      }),
    );
  }
}

function* confirmSignUp({ payload }: ReturnType<typeof signUpConfirmed>) {
  const { email, code, url } = payload;
  yield put(
    setUserState({
      loading: true,
    }),
  );

  try {
    const response = yield call(usr.confirmSignUp, email, code, _store.get('custom_auth_session'));
    if (response?.success) {
      yield put(loadCurrentAccountAction({}));
    } else {
      const errorCode =
        response?.error && ConfirmSignUpErrorCode[response.error] ? ConfirmSignUpErrorCode[response.error] : undefined;
      yield router.navigate(url, {
        replace: true,
        state: { email, errorCode },
      });
      yield put(
        setUserState({
          loading: false,
        }),
      );
    }
  } catch (err) {
    yield router.navigate(url, { state: { email }, replace: true });
    yield put(
      setUserState({
        loading: false,
      }),
    );
  }
}

function* resendConfirmSignUp({ payload }: ReturnType<typeof resentConfirmSignUp>) {
  const intl = getIntl();
  const { email, pathname } = payload;

  try {
    const response = yield call(usr.resendConfirmSignUp, email);
    if (response) {
      if (!response.success || !response.session || response.error) {
        yield router.navigate(pathname, {
          state: {
            emailSent: true,
            emailResent: true,
            email,
            errorCode: ConfirmSignUpErrorCode.RESEND_ERROR,
          },
          replace: true,
        });
        yield call(showErrorToast, { description: intl.formatMessage({ id: `alert.error` }) });
      } else {
        // Save session in local storage
        _store.set('custom_auth_session', response.session);

        // This endpoint is called from both /auth/sign-up and /auth/confirm-sign-up
        yield router.navigate(pathname, {
          state: {
            emailSent: true,
            emailResent: true,
            email,
          },
          replace: true,
        });
      }
    }
  } catch (err) {
    yield router.navigate(pathname, {
      state: {
        emailSent: true,
        emailResent: true,
        email,
        errorCode: ConfirmSignUpErrorCode.RESEND_ERROR,
      },
      replace: true,
    });
    yield call(showErrorToast, { description: intl.formatMessage({ id: `alert.error` }) });
  }
}

function* confirmMfa({ payload }: ReturnType<typeof mfaConfirmed>) {
  const { code, session, email } = payload;

  yield put(
    setUserState({
      loading: true,
      badVerificationCode: false,
    }),
  );

  try {
    const response = yield call(usr.confirmMfa, code, session, email);
    if (response) {
      const redirectTo = _store.get('redirectTo');

      yield put(
        loadCurrentAccountAction({
          redirectTo: redirectTo || '/',
        }),
      );
    } else {
      yield put(
        setUserState({
          loading: false,
          badVerificationCode: true,
        }),
      );
    }
  } catch (err) {
    yield put(
      setUserState({
        loading: false,
        badVerificationCode: true,
      }),
    );
  }
}

export function* sagas() {
  yield all([
    takeEvery(loginAction, login),
    takeEvery(loadCurrentAccountAction, loadCurrentAccount),
    takeEvery(logoutAction, logout),
    takeEvery(passwordChanged, changePassword),
    takeEvery(forgotPasswordAction, forgotPassword),
    takeEvery(passwordReset, resetPassword),
    takeEvery(signUpAction, signUp),
    takeEvery(signUpConfirmed, confirmSignUp),
    takeEvery(resentConfirmSignUp, resendConfirmSignUp),
    takeEvery(inviteConfirmed, confirmInvite),
    takeEvery(mfaConfirmed, confirmMfa),

    loadCurrentAccount(), // run once on app load to check user auth
  ]);
}
