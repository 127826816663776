import { WarningRhombusFilledIcon } from '@pledge-earth/product-language';

import { ErrorBase } from '../Base/ErrorBase';

/**
 * Last resort error component that does not use INTL
 */
export function LastResortError() {
  return (
    <ErrorBase
      icon={<WarningRhombusFilledIcon />}
      title="500: Unexpected error"
      description="Sorry, something went wrong."
    />
  );
}
